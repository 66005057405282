<template>
  <HMButton :color="color" @click="openTipModal">
    <div class="d-flex align-items-baseline justify-content-center">
      <i class="far fa-heart me-2" :class="color"></i>
      <span>{{ $t('STUDIO.BUTTON.LEAVE_A_TIP') }}</span>
    </div>
  </HMButton>
</template>

<script>
import TipJarModal from '@/components/settings/TipJarModal';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';

export default {
  name: 'HMTipButton',
  props: {
    color: {
      type: String,
      default: 'brand',
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      currency: 'currency',
    }),
  },
  methods: {
    openTipModal() {
      this.$modal.show(TipJarModal, {
        studioName: this.studio.studio_name,
        currency: this.currency,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.brand {
  color: $white;
}
.outline-gray {
  color: var(--brand-color, $coral);
}
</style>
